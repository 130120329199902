import styled, { css } from "styled-components";
import { device } from "../data/data-french";

//  General purpose variables
export const startColor = "var(--white)";
export const endColor = "var(--cultured-white)";
//  export const endColor = "var(--eerie-black)";

export const API_PATH = "https://elysium.cc/ignacin/APPS/__WEBSITE/index.php";

// Styling
interface Flex {
  flow: string;
  justify: string;
  align: string;
  wrap?: string;
}

/* ---  Boilderplate CSS code  --- */
export const FlexContainer = ({ flow, justify, align, wrap }: Flex) => `
    /* --- Flex parent --- */
    display: flex;
    flex-flow: ${flow} ${wrap || "nowrap"};
    justify-content: ${justify};
    align-items: ${align};
`;

export const ScrollBarStyles = css`
  // Scrollbar doesn't fit with minimalistic design
  &::-webkit-scrollbar {
    // width: 10px;
    height: 0rem; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--lighter-black);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

/* ---  Reusable basic Components  --- */
export const HeaderButtonStyles = css`
  background-color: transparent;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid var(--lighter-black);
  color: var(--lighter-black);
  font-size: 3vw;
  opacity: var(--opacity-header-button);

  &:hover {
    border-radius: var(--border-radius-header-button);
  }
`;

export const TitleContainer = styled.div`
  // Flex child
  flex: 0 0 auto;
  width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "center", align: "center" })}

  /* Other Styles */
  border: var(--show-borders) solid red;
  margin: var(--height-header) 0 0 0;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    margin: 0;
  }
`;

export const Title = styled.h1`
  color: var(--lighter-black);
  /* font-size: var(--font-size-title); */
  /* font-size: clamp(1rem, 4vw, 3rem); */
  font-size: var(--payment-plans-title-font);

  // Override default h1 styles
  margin: 0;
`;

export const Paragraph = styled.p`
  color: var(--lighter-black);
  font-size: var(--font-size-paragraph);
  font-weight: var(--font-weight-paragraph);
  text-align: justify;
`;

export const Label = styled.label`
  /* --- Flex parent --- */
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;

  /* Other styles */
  color: var(--lighter-black);
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-title);
`;

export const BigButton = styled.button`
  /* --- Probably flex child --- */
  width: 100%;
  height: 3.5rem;

  /* Other Styles */
  background-color: var(--lighter-black);
  border: none;
  border-radius: 0.4rem;
  color: var(--cultured-white);

  transition: background-color 0.7s, color 0.7s;

  &:hover {
    background-color: transparent;
    border: 2px solid var(--lighter-black);
    color: var(--lighter-black);
  }
`;

export const Arrow = styled.svg`
  width: 1.7rem;
`;

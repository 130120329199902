import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikState,
} from "formik";
import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { BoxContent, FormInterface } from "../data/data-french";
import Box, { Boxed } from "./Box";
import axios from "axios";
import { API_PATH } from "../styles/variables";
import { FlexContainer, Label, BigButton } from "../styles/variables";
import { ToastContext, ToastInfo } from "../Context/ToastContext";

interface Props {
  firstBox?: boolean;
  invertedDivs?: boolean;
  oddBox?: boolean;
  content: BoxContent;
  id: string;
  slideId: string;
  btnTitle?: string;
}

interface FormStyledProps {
  name: string;
  placeholder: string;
  errors: any;
  touched: any;
  validate: () => void;
  isTextArea?: boolean;
}

// Main container
const FormBoxContainer = styled(Box)<Props>`
  /* --- Flex child --- */
  flex: 1 0 auto;
  height: 100vh;
`;

// Formik container
const FormWrapper = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-end", align: "center" })}

  /* Other Styles */
  padding: 0 4rem;
`;

// Form container
const FormContainer = styled(Form)`
  /* Flex child */
  flex: 0 1 auto;
  width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({
    flow: "row",
    justify: "space-between",
    align: "stretch",
    wrap: "wrap",
  })}

  /* Other Styles */
  /* --- Borders --- */
  border: var(--show-borders) solid black;
`;

// Form labels column
const FormLabelsContainer = styled.div`
  /* --- Flex child --- */
  flex: 1 1 0px;

  border: var(--show-borders) solid purple;

  /* --- Flex parent --- */
  ${FlexContainer({
    flow: "column",
    justify: "space-between",
    align: "flex-start",
  })}
`;

// Form fields column
const FormFieldsContainer = styled.div`
  /* --- Flex child --- */
  flex: 3 1 0px;

  border: var(--show-borders) solid blue;

  /* --- Flex parent --- */
  ${FlexContainer({
    flow: "column",
    justify: "flex-between",
    align: "flex-end",
  })}
`;

const FormElementStyled = css`
  /* --- Flex child --- */
  /* flex: 0 1 70%; */

  /* Other Styles */
  border: var(--show-borders) px solid var(--lighter-black);
  border-radius: 4px;
  opacity: 0.7;
  padding: 0;
`;

const FormLabel = styled(Label)`
  /* Flex child */
  flex: 1 1 auto; // Default
  height: 3rem;
  width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "flex-start" })}

  /* color: var(lighter-black); */
  font-size: var(--font-size-formlabel);
  padding-right: 1rem;
`;

const FormField = styled(Field)`
  /* Conditional Styles */
  ${FormElementStyled}
  color: ${(props: FormStyledProps) =>
    props.touched.email && props.errors.email
      ? "var(--ruby-red)"
      : "var(--lighter-black)"};

  

  /* Flex child */
  flex: 0 1 auto; // Default
  height: 3rem;
  height: ${(props: FormStyledProps) => props.isTextArea && "6rem;"};
  width: 100%;

  /* Other Styles */
  background-color: var(--light-gray);
  border: 0px solid var(--lighter-black);
  border-radius: 0.4rem;
  margin: 1rem 0;
  padding: 0 0 0 1rem;
`;

const ErrorMessageStyled = styled(ErrorMessage)`
  ${FormElementStyled}
  border: none;
  color: var(--ruby-red);
`;

const FormSubmitButton = styled(BigButton)`
  /* This allows to wrap the last flex child into a new line  */
  margin-left: auto;
`;

function validateEmail(value: string) {
  let error;
  if (!value) {
    error = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address format";
  }

  return error;
}

function validateMessage(value: string) {
  let error;
  if (!value) {
    error = "Say something about yourself";
  } else if (value.length < 3) {
    error = "Your message is too short";
  }
  return error;
}

export const FormBox: React.FC<Props> = (props: Props) => {
  const [toastInfo, setToastInfo] = useContext(ToastContext);

  useEffect(() => {
    if (toastInfo.shouldDisplayToast) {
      setTimeout(
        () =>
          setToastInfo((prevState: ToastInfo) => ({
            ...prevState,
            shouldDisplayToast: false,
          })),
        3000
      );
    }
  }, [toastInfo]);

  // Put this inside your component before the return statement
  const onSubmit = (
    values: any | any[],
    {
      setSubmitting,
      resetForm,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      resetForm: (
        nextState?: Partial<FormikState<FormInterface>> | undefined
      ) => void;
    }
  ) => {
    // alert(JSON.stringify(values));
    // form is valid
    axios({
      method: "post",
      url: `${API_PATH}`,
      data: JSON.stringify(values),
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        resetForm();
        setToastInfo((prevState: ToastInfo) => ({
          shouldDisplayToast: true,
          message: "Email was sent",
        }));
      })
      .catch(function (response) {
        //handle error
        console.log("Error sending mail: ", response);
        alert(
          "There was a problem. The mail coudln't be sent. If the problem persists, please send an email to the address in the footer. We apologize for the inconvenience."
        );
      });
  };

  return (
    <FormBoxContainer
      firstBox={props.firstBox}
      invertedDivs={props.invertedDivs}
      oddBox={props.oddBox}
      content={props.content}
      id={props.id}
      slideId={""}
      btnTitle={props.btnTitle}
    >
      <FormWrapper>
        <Formik
          initialValues={
            {
              name: "",
              email: "",
              firm: "",
              message: "",
            } as FormInterface
          }
          onSubmit={(values, { setSubmitting, resetForm }) =>
            onSubmit(values, { setSubmitting, resetForm })
          }
        >
          {({ errors, touched }) => (
            <FormContainer id="form">
              <FormLabelsContainer>
                {" "}
                <FormLabel htmlFor="name">Name</FormLabel>
                <FormLabel htmlFor="firm">Firm</FormLabel>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <FormLabel htmlFor="message">Message</FormLabel>
              </FormLabelsContainer>

              <FormFieldsContainer>
                <FormField
                  name="name"
                  placeholder="Enter your name"
                  errors={errors}
                  touched={touched}
                  validate={() => {}}
                />
                <ErrorMessageStyled
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
                <FormField
                  name="firm"
                  placeholder="Enter your Firm name"
                  errors={errors}
                  touched={touched}
                  validate={() => {}}
                />
                <ErrorMessageStyled
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />

                <FormField
                  name="email"
                  placeholder="Enter your email"
                  errors={errors}
                  touched={touched}
                  validate={validateEmail}
                />
                <ErrorMessageStyled
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />

                <FormField
                  name="message"
                  placeholder="Enter your message"
                  errors={errors}
                  touched={touched}
                  validate={validateMessage}
                  isTextArea={true}
                />
                <ErrorMessageStyled
                  component="div"
                  name="message"
                  className="invalid-feedback"
                />
              </FormFieldsContainer>

              <FormSubmitButton type="submit">Submit</FormSubmitButton>
            </FormContainer>
          )}
        </Formik>
      </FormWrapper>
    </FormBoxContainer>
  );
};

import React from "react";
import styled, { css } from "styled-components";
import {
  BronzeData,
  device,
  GoldData,
  PlansData,
  PlanType,
  SilverData,
} from "../data/data-french";
import { FlexContainer, Title, TitleContainer } from "../styles/variables";
import { startColor, endColor } from "../styles/variables";
import { Arrow } from "../styles/variables";

import { Plan } from "./Plan";
import { ReturnButton } from "./ReturnButton";

interface Props {
  id: string;
  slideId: string;
  oddBox?: boolean;
}

interface StyledProps {
  oddBox?: boolean;
}

interface StyledPropsWithPlanType {
  planType: BronzeData | SilverData | GoldData;
}

const PaymentPlansContainer = styled.div`
  /* Conditional Styles come first */
  background: linear-gradient(to bottom, ${startColor}, ${endColor});
  ${(props: StyledProps) =>
    props.oddBox &&
    `background: linear-gradient(to bottom, ${endColor},  ${startColor});`};

  // Flex child
  flex: 0 0 100%;
  /* height: 100%; */

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "center" })}

  /* Other Styles*/
  border: var(--show-borders) solid black;

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* --- Flex parent --- */
    ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}
  }
`;

const PaymentTitleContainer = styled(TitleContainer)`
  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    overflow-y: scroll;
  }
`;

const PaymentTitle = styled(Title)`
`;

const PlansContainer = styled.div`
  // Flex child
  flex: 0 1 75%;
  width: 100%;
  max-width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "center", align: "stretch" })}

  /* Other Styles */
  border: var(--show-wborders) solid lightblue;
  padding: 1rem 0;

  @media ${device.laptop} {
    /* Flex child */
    flex: 0 1 100%;

    /* --- Flex parent --- */
    ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}

    /* Special Styles */
    overflow-y: scroll;
  }
`;

const PlanOuterWrapper = styled.div`
  /* Flex child */
  flex: 1 1 0px;
  height: 100%;
  max-width: 400px;

  /* --- Not a flex parent --- */

  /* Other Styles */
  padding: 0 1rem 0 1rem;
  border: var(--show-borders) px solid black;

  @media ${device.laptop} {
    /* Flex child */
    width: 100%;
    max-width: 100%;
  }
`;

const PlanInnerWrapper = styled.div<StyledPropsWithPlanType>`
  /* Conditional Styles */
  ${(props) =>
    (props.planType.planName === "silver" || props.planType.planName === "argent")  &&
    css`
      background-image: radial-gradient(
        circle,
        #3d8bd3 0%,
        #7b86ff 48%,
        #8d7bff 85%,
        #7284e8 98%
      );
    `}

  /* Not a flex child */
  height: 100%;
  max-width: inherit;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "stretch" })}

  /* Other Styles */
  border-radius: 0.8rem;
  /* A 1px padding allows the 'p' child to have a margin */
  padding: 2rem 0.5rem 0.5rem;

  /* Add this in order to position MFp correctly */
  position: relative;

  @media ${device.laptop} {
    flex: 1 1 0px;
  }
`;

const MFp = styled.p`
  /* Other Styles*/
  color: var(--white);
  font-size: var(--font-weight-logo-and-bold-title);
  font-weight: var(--font-weight-logo-and-bold-title);
  margin: 0 0 1rem 0;
  text-align: center;
  text-transform: uppercase;

  /* Lil hack to get it  workin' */
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
`;

export const PaymentPlans: React.FC<Props> = (props: Props) => {
  return (
    <PaymentPlansContainer oddBox={props.oddBox} id={props.id}>
      <PaymentTitleContainer>
        <ReturnButton id={props.slideId} btnTitle={""}>
          <Arrow
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M249.38 336L170 256l79.38-80M181.03 256H342"
            />
            <path
              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="32"
            />
          </Arrow>
        </ReturnButton>
        <PaymentTitle>{PlansData.title}</PaymentTitle>
      </PaymentTitleContainer>
      <PlansContainer>
        {(
          Object.keys(PlansData.plan) as Array<keyof typeof PlansData.plan>
        ).map((key: keyof typeof PlansData.plan, index: number) => {
          return (
            <PlanOuterWrapper key={index}>
              <PlanInnerWrapper planType={PlansData.plan[key]}>
                <MFp hidden={!(PlansData.plan[key].planName === "silver" || PlansData.plan[key].planName === "argent")}>
                  Most popular
                </MFp>
                <Plan planType={PlansData.plan[key]} />
              </PlanInnerWrapper>
            </PlanOuterWrapper>
          );
        })}
      </PlansContainer>
    </PaymentPlansContainer>
  );
};

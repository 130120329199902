import React from "react";
import styled, { css } from "styled-components";
import {
  BronzeData,
  BronzeFunctionalities,
  device,
  GoldData,
  otherData,
  PlanName,
  PlansData,
  PlanType,
  SilverData,
} from "../data/data-french";
import { FlexContainer } from "../styles/variables";

interface Props {
  planType: BronzeData | SilverData | GoldData;
}

// Place them here for conditional styles
const PlanHeaderAndBodyStyles = css`
  overflow-y: scroll;
  @media ${device.laptop} {
    flex: 1 1 0px;
    overflow-y: scroll;

    transition: all 2s ease-in-out;
  }
`;

const PlanHeader = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({
    flow: "column",
    justify: "flex-start",
    align: "flex-start",
  })}

  //   Flex child
  flex: 1 1 0px;
  width: 100%;

  border: var(--show-borders) solid black;
  ${PlanHeaderAndBodyStyles}
`;

// const PlanBody = styled.div`
//   //   Flex child
//   flex: 1 1 0px;
//   width: 100%;

//   /* Other Styles */
//   border: var(--show-borders) solid black;
//   opacity: 0.9;
//   overflow-y: scroll;
//   ${PlanHeaderAndBodyStyles}
// `;

const PlanContainer = styled.div<Props>`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "stretch" })}

  // Not a flex child anymore
  /* This allows each plan to grow and shrink equally, theoretically */
  flex: 1 1 0px;
  height: 100%;
  max-width: inherit;

  /* Prevent content to go into padding */
  min-width: var(border);

  /* Other Styles */
  background-color: var(--cultured-white);
  border: var(--show-borders) solid var(--lighter-black);
  border-radius: var(--border-radius-payment-plan);
  color: var(--lighter-black);
  margin: 0;
  padding: 2rem;

  /* Special Styles */
  box-shadow: 0 12px 28px rgba(140, 149, 159, 0.3);

  &:hover {
    /* These properties have to be given to transition */
    background-color: var(--lighter-black) !important;
    color: var(--white);
  }

  /* Special Styles */
  transition: all 0.7s ease-in-out;

  @media ${device.laptop} {
    flex-flow: column nowrap;
    margin: 1rem;

    /* We want the plans to shrink as small as necessary */
    /* overflow-y: scroll; */

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
    height: auto;

    transition: all 1s;
    &:hover {
      /* PlanBody  */
      ${PlanHeader} {
        /* Show the ul when clicked (defaults to hover on mobile)*/
        /* flex: 1 1 100%; */
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 200px;
        height: 100%;
      }
    }
  }
`;

const MostPopularContainer = styled.div`
  /* --- Flex child --- */
  flex: 0 0 auto;
  border: 2px solid red;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "center" })}
`;

const MostPopularBorder = styled.p<Props>`
  /* Position the text in the padding */
  position: relative;
  top: -3.9rem;
  font-weight: var(--font-weight-logo-and-bold-title);
  color: var(--white);

  margin: 0;

  /* Other Styles */
  background-color: transparent;

  /* Special Styles */
  text-transform: uppercase;
  text-align: center;
`;

const PlanTitle = styled.h1`
  // Flex child
  flex: 0 0 auto;
  align-self: flex-start;

  font-size: var(--font-size-small-title);
  //   font-weight: var(--font-weight-bold-title);
  border: var(--show-borders) solid purple;
  margin: 0;
  padding: 0;
  // Text styling
  text-align: center;
  text-transform: capitalize;
  ${(props: Props) =>
    props.planType.planName === "bronze" &&
    css`
      color: var(--bronze);
    `}
  ${(props: Props) =>
    (props.planType.planName === "silver" || props.planType.planName === "argent")  &&
    css`
      color: var(--silver);
    `}
  ${(props: Props) =>
    (props.planType.planName === "gold" || props.planType.planName === "or") &&
    css`
      color: var(--gold);
    `}
`;

const PlanPriceContainer = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "flex-start", align: "center" })}

  border: var(--show-borders) solid black;

  // Flex child
  flex: 0 0 auto;
  width: 100%;
  padding: 0.5rem 0;
`;

const PlanPrice = styled.div`
  // Flex child
  flex: 0 0 auto;

  /* Other styles */
  border: var(--show-borders) solid red;
  font-size: 2.8rem;
  font-size: var(--payment-plans-title-font);
  height: 100%;
`;

const PerMonth = styled.div`
  // Flex child
  flex: 1 0 auto;

  border: var(--show-borders) solid red;
  opacity: 0.7;
`;

const PlanDescription = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}

  /* --- Flex child --- */
  flex: 0 0 20%;

  /* Other Styles */
  border: var(--show-borders) solid black;
  font-size: var(--font-size-small-text);
  font-weight: var(--font-weight-paragraph);
  // color: var(--lighter-black);
  opacity: 0.8;
  margin: 0;
  padding: 1rem 0;
`;

// PlanBody should be here but has been moved to the top because it couldn't hoist itself
// You gotta help your weak friends sometimes

const StyledUl = styled.ul`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "stretch" })}

  /* Other Styles */
  line-height: 1.8rem;
  list-style: none;
  padding: 0;
`;

const PlanItemContainer = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "flex-start", align: "stretch" })}
`;

const PlanItem = styled.h1`
  /* Other Styles */
  font-size: 0.9rem;
  font-weight: calc(var(--font-weight-paragraph));
  margin: 0;
  // Ensures the bullet point is replaced by a checkmark

  // Ensures the ✔ are well alined and spaced with the text
  padding-left: 1rem;
`;

const PlanItemImageContainer = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "center" })}
`;

export const Plan: React.FC<Props> = (props: Props) => {
  const planType = props.planType;
  return (
    // <PlanInnerWrapper planType={planType}>
    <PlanContainer planType={planType}>
      {/* <MostPopularBorder
          planType={planType}
          hidden={!(planType.planName === "silver")}>
          Most popular
        </MostPopularBorder> */}

      <PlanHeader>
        <PlanTitle planType={planType}>{planType.planName}</PlanTitle>
        <PlanPriceContainer>
          <PlanPrice>{planType.priceTag}</PlanPrice>
          <PerMonth>&nbsp;&nbsp;/ {otherData.plan.paymentInfo.month}</PerMonth>
        </PlanPriceContainer>
        <PlanDescription>{planType.planDescription}</PlanDescription>

        {/* </PlanHeader>
      <PlanBody> */}

        <StyledUl>
          {(
            Object.keys(planType.functionalities) as Array<
              keyof typeof planType.functionalities
            >
          ).map((key: keyof typeof planType.functionalities, index: number) => {
            return (
              <PlanItemContainer key={index}>
                <PlanItemImageContainer>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="15"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Checkmark Done Circle</title>
                    <path
                      d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M368 192L256.13 320l-47.95-48M191.95 320L144 272M305.71 192l-51.55 59"
                    />
                  </svg>{" "}
                </PlanItemImageContainer>
                <PlanItem key={index}>{planType.functionalities[key]}</PlanItem>
              </PlanItemContainer>
            );
          })}
        </StyledUl>
      </PlanHeader>
    </PlanContainer>
    // </PlanInnerWrapper>
  );
};

import React from "react";
import styled from "styled-components";
import { TypeFormatFlags } from "typescript";
import {
  AdvantageInterface,
  Advantages,
  AdvantagesData,
  device,
  PlansData,
  PlanType,
  Reasons,
} from "../data/data-french";
import {
  startColor,
  endColor,
  TitleContainer,
  Title,
  Arrow,
} from "../styles/variables";
import { Card } from "./Card";
import { Plan } from "./Plan";
import { DiscoverButton, scrollToSlide } from "./DiscoverButton";
import { ReturnButton } from "./ReturnButton";
import { FlexContainer } from "../styles/variables";

interface Props {
  id: string;
  slideId: string;
  oddBox: boolean;
}

interface StyledProps {
  id: string;
  oddBox: boolean;
}

const AdvantagesBoxCardsContainer = styled.div`
  /* Conditional Styles come first */
  background: linear-gradient(to bottom, ${startColor}, ${endColor});
  ${(props: StyledProps) =>
    props.oddBox &&
    `background: linear-gradient(to bottom, ${endColor},${startColor});`};

  // Flex child
  flex: 0 0 100%;
  /* height: 100%; */
  max-height: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "center" })}

  /* Other Styles */
  border: var(--show-borders) solid black;

  /* Special Styles */
  overflow-y: hidden;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    flex: 1 0 100%;
    justify-content: flex-start;
  }
`;

const ArrowSvg = styled(Arrow)``;


const AdvantagesTitleContainer = styled(TitleContainer)`
  /* Other Styles */
  border: var(--show-borders) solid red;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    /* Flex child */
    flex: 1 5 0px;

    /* Other Styles */
    padding: 2rem 1rem;
  }
`;

const AdvantagesTitle = styled(Title)``;

const CardsWrapper = styled.div`
  // Flex child
  flex: 0 0 80%;
  width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "space-around", align: "stretch" })}

  /* Other Styles */
  border: var(--show-borders) solid lightblue;
  padding: 2rem 1rem 1rem 1rem;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    /* Flex child */
    flex: 7 1 0px; // Why ??

    /* --- Flex parent --- */
    ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}

    // For some reason, any other value than default 'visible' will prevent vertical overflow
    overflow-y: auto;
  }
`;

export const AdvantagesBoxCards: React.FC<Props> = (props: Props) => {
  return (
    <AdvantagesBoxCardsContainer oddBox={props.oddBox} id={props.id}>
      <AdvantagesTitleContainer>
        <ReturnButton id={props.slideId} btnTitle={""}>
          <ArrowSvg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M249.38 336L170 256l79.38-80M181.03 256H342"
            />
            <path
              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="32"
            />
          </ArrowSvg>
        </ReturnButton>

        <AdvantagesTitle>{AdvantagesData.title}</AdvantagesTitle>
      </AdvantagesTitleContainer>
      <CardsWrapper id="cardswrapper">
        {(
          (
            Object.keys(AdvantagesData) as Array<keyof typeof AdvantagesData>
          ).filter(
            (property) => typeof AdvantagesData[property] != "string"
          ) as Array<keyof Reasons>
        ).map((property, index: number) => {
          return <Card key={index} index={index} property={property}></Card>;
        })}
      </CardsWrapper>
    </AdvantagesBoxCardsContainer>
  );
};

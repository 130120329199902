import React from "react";
import styled from "styled-components";
import { device, PlansData, TemplateData } from "../data/data-french";
import {
  endColor,
  FlexContainer,
  startColor,
  Title,
  TitleContainer,
} from "../styles/variables";
import { Arrow } from "../styles/variables";
import Photo from "./Photo";
import { ReturnButton } from "./ReturnButton";

interface Props {
  id: string;
  slideId: string;
  oddBox: boolean;
}

interface StyledProps {
  oddBox?: boolean;
}

const TemplateContainer = styled.div<StyledProps>`
  background-color: ${(props) => props.oddBox && "var(--cultured-white)"};
  background: linear-gradient(to bottom, ${startColor}, ${endColor});
  ${(props) =>
    props.oddBox &&
    `background: linear-gradient(to bottom, ${endColor},  ${startColor});`};

  // Flex child
  flex: 0 0 100%;
  height: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}

  /* height: 100%; */
  border: var(--show-borders) solid gray;
`;

const TemplateTitleContainer = styled(TitleContainer)``;

const TemplateTitle = styled(Title)``;

const PhonesStyled = styled(Photo)`
  border: var(--show-borders) solid black;
  /* height: auto; */
  max-width: 100%;
  padding: 4rem 2rem 1rem;
  /* width: 100%; */

  img {
    max-width: 100%;
  }
`;

export const TemplateBox: React.FC<Props> = (props: Props) => {
  return (
    <TemplateContainer id={props.id} oddBox={props.oddBox}>
      <TemplateTitleContainer>
        <ReturnButton id={props.slideId} btnTitle={""}>
          <Arrow
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M249.38 336L170 256l79.38-80M181.03 256H342"
            />
            <path
              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="32"
            />
          </Arrow>
        </ReturnButton>
        <TemplateTitle>{TemplateData.title}</TemplateTitle>
      </TemplateTitleContainer>

      <PhonesStyled src="static_assets/PHONES.png" />
      {/* <Photo src="/static_assets/PHONES.png"/> */}
    </TemplateContainer>
  );
};

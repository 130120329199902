import React from "react";
import styled from "styled-components";
import { otherData } from  "../data/data-french";
import { device } from "../data/data-french";
import { FlexContainer } from "../styles/variables";

interface Props {}

const FooterContainer = styled.footer`
  /* --- Flex child --- */
  flex: 1 1 0px;
  min-height: 30%;
  width: 100%;

  /* Not a flex parent */

  /* Other Styles */
  background-color: var(--lighter-black);
  border: var(--show-borders) solid green;
  color: var(--cultured-white);
  padding: 0 0 2rem 0;
`;

const FooterWrapper = styled.div`
  /* Not a flex child */
  width: 100%;
  height: 100%;
  padding: 1rem;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "flex-start", align: "center" })}

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* --- Flex parent --- */
    ${FlexContainer({
      flow: "column",
      justify: "flex-start",
      align: "flex-start",
    })}
  }
`;

const FooterColumn = styled.div`
  /* --- Flex child --- */
  flex: 0 0 max-content;
  height: 100%;

  /* Other Styles */
  border: var(--show-borders) solid grey;
  padding: 1rem;
`;

const FooterTitle = styled.div`
  /* Other Styles */
  color: var(--cultured-white);
  font-weight: var(--font-weight-title);
  text-transform: uppercase;
`;

const Nav = styled.nav`
  /* --- Flex child --- */
  color: var(--light-gray);

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "flex-start" })}

  a {
    color: var(--light-gray);
    display: inline;
    text-decoration: none;
  }
`;

export const Footer: React.FC<Props> = ({ children, ...props }) => {
  return (
    <FooterContainer className="Footer" id="footer">
      <FooterWrapper>
        <FooterColumn>
          <Nav>
            <div>
              Illustration by Icons8 from{" "}
              <a href="https://icons8.com/illustrations">Ouch!</a>
            </div>
            <div>ELYSIUM Sarl © 2021</div>
          </Nav>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>
            <label>{otherData.footer.columnNames.social}</label>
          </FooterTitle>
          <Nav>
            {/* <a href="https://www.instagram.com/ig.naaah" target="_blank">
              Instagram
            </a> */}
            {/* <a href="https://twitter.com/ignaaaaaaaaaa" target="_blank">
              Twitter
            </a> */}
            <a href="https://www.elysium.cc" target="_blank">
              Elysium - Other services
            </a>
          </Nav>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>
            <label>{otherData.footer.columnNames.contact}</label>
          </FooterTitle>
          <Nav>
            <a href="mailto:restaurants@elysium.cc" target="_blank">
              restaurants@elysium.cc
            </a>
          </Nav>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>
            <label>{otherData.footer.columnNames.security}</label>
          </FooterTitle>
          <Nav>
            <a href="mailto:security@elysium.cc" target="_blank">
              {otherData.footer.bug}
            </a>
          </Nav>
        </FooterColumn>
      </FooterWrapper>
    </FooterContainer>
  );
};

import styled from "styled-components";
import { HeaderButtonStyles } from "../styles/variables";
import { scrollToSlide } from "./DiscoverButton";

interface Props {
  title: string;
  url?: string;
}

const ContactButton = styled.button`
  /* Provides 'clickability' */
  pointer-events: auto;

  /* Flex child */
  flex: 0 0 auto;
  height: 40px;

  /* Other Styles */
  ${HeaderButtonStyles}
  font-size: var(--font-size-button);
  margin: 0 1rem;
  
  padding: 0 2rem;
  text-transform: uppercase;

  transition: background-color 0.7s, color 1s;

  &:hover {
    color: var(--white);
    background-color: var(--lighter-black);
  }
`;

const HeaderButton = (props: Props) => {
  function handleClick() {
    props.url != undefined
      ? (window.location.href = props.url)
      : scrollToSlide("footer");
  }

  return (
    <ContactButton
      className="HeaderButton"
      onClick={() => {
        handleClick();
      }}
    >
      {props.title}
    </ContactButton>
  );
};

export default HeaderButton;

interface ID {
  id: string;
  slideId: string;
}

export interface BoxContent extends ID {
  firstTitle: string;
  secondTitle: string;
  paragraph: string;
  src: string;
  btnTitle?: string;
}

export interface GoalsData extends ID {
  title: string;
  btnTitle: string;
}

export interface AdvantageInterface {
  reason: string;
  paragraph: string;
  src: string;
}

export interface Reasons {
  first: AdvantageInterface;
  second: AdvantageInterface;
  third: AdvantageInterface;
  fourth: AdvantageInterface;
  fifth: AdvantageInterface;
}
export interface Advantages extends ID, Reasons {
  title: string;
}

export interface TemplateSlide extends ID {
  title: string;
}

export type PlanName = "bronze" | "argent" | "or";
export type OrderMethod = string;
export type PaymentMethod = string;

export interface BronzeFunctionalities {
  templates: string;
  orderMethod: OrderMethod;
  paymentMethod: PaymentMethod;
  support: string;
}

export interface SilverFunctionalities extends BronzeFunctionalities {
  web: string;
  fidelity: string;
}

export interface GoldFunctionalities extends SilverFunctionalities {
  stats: string;
}

export interface NameAndPrice {
  planName: PlanName;
  priceTag: string;
  planDescription: string;
}
export interface BronzeData extends NameAndPrice {
  functionalities: BronzeFunctionalities;
}

export interface SilverData extends NameAndPrice {
  functionalities: SilverFunctionalities;
}

export interface GoldData extends NameAndPrice {
  functionalities: GoldFunctionalities;
}

export interface PlanType {
  bronze: BronzeData;
  silver: SilverData;
  gold: GoldData;
}

export interface PaymentPlans extends ID {
  plan: PlanType;
  title: string;
}

export interface FormInterface {
  name: string;
  email: string;
  firm: string;
  message: string;
}

// First Layer
export const ProductsData: BoxContent = {
  id: "templatebox",
  slideId: "templateslide",
  firstTitle: "Nous developpons votre application et site web",
  secondTitle: "",
  paragraph:
    "Vos clients peuvent passer leurs commandes, réserver une table et régler depuis votre application. Ils vont vous adorer et revenir.",
  src: "static_assets/loading-screen-pizza.png",
};

export const TemplateData: TemplateSlide = {
  id: "templateslide",
  slideId: "templatebox",
  title: "Votre application en 4 étapes :",
};

// Intro Layer
export const PresentationData: BoxContent = {
  id: "goalsbox",
  slideId: "presentationbox",
  firstTitle:
    "Nous sommes une entreprise genevoise specialisée dans les services digitaux pour la restauration",
  secondTitle: "",
  paragraph: "",
  src: "static_assets/loading-screen-pizza.png",
};

export const GoalsData: GoalsData = {
  id: "presentationbox",
  title: "Helllllo",
  slideId: "goalsbox",
  btnTitle: "Who we are",
};

// Second layer
export const FastData: BoxContent = {
  id: "introbox",
  slideId: "advantages",
  firstTitle: "Rapides.",
  secondTitle: "Très rapides.",
  paragraph:
    "En moins d'un mois, votre application iOS et Android sera sur le marché",
  src: "static_assets/3d-flame-blockchain.png",
};

export const AdvantagesData: Advantages = {
  id: "advantages",
  slideId: "introbox",
  title: "Découvrez les nombreux avantages d'avoir une application mobile",
  first: {
    reason: "C'est votre application. Pas de commissions.",
    paragraph:
      "Un abonnement fixe mensuel, sans coûts variables ni commissions. Gardez vos revenus entièrement pour vous.",
    src: "static_assets/3d-flame-man-with-info-2.png",
  },
  second: {
    reason: "Dites au revoir aux appels téléphoniques",
    paragraph:
      "Utilisez la technologie à votre avantage et gardez une longueur d'avance sur vos concurrents.",
    src: "static_assets/3d-flame-customer-assistance.png",
  },
  third: {
    reason: "Apportez une nouvelle source de revenus à la table",
    paragraph:
      "Augmentez vos chiffres d'affaires en visant une audience jeune et digitalisée.",
    src: "static_assets/3d-flame-man-with-info-3.png",
  },
  fourth: {
    reason: "Recevez et gérez toutes vos commandes sur une seule plateforme.",
    paragraph: "",
    src: "static_assets/3d-flame-productivity.png",
  },
  fifth: {
    reason: "Profitez d'un service complet",
    paragraph:
      "Ne vous souciez plus de coûts externes liés à des services de maintenance, de support ou d'hébergement. Nous nous occupons de tout.",
    src: "static_assets/business-3d-assistant.png",
  },
};

// Third layer
export const PaymentsData: BoxContent = {
  id: "paymentsbox",
  slideId: "paymentsplans",
  firstTitle: "",
  secondTitle: "Flexible et abordable, aussi longtemps que vous le souhaitez",
  paragraph:
    "Choissez  l'abonnement qui vous convient en fonction de vos besoins",
  src: "static_assets/3d-bitcoin-standing.png",
};

export const PlansData: PaymentPlans = {
  id: "paymentsplans",
  slideId: "paymentsbox",
  plan: {
    bronze: {
      planName: "bronze",
      planDescription: "Recommandé pour les fast-foods",
      priceTag: "CHF 149.-",
      functionalities: {
        templates: "1 Application - 1 interface",
        paymentMethod: "Paiement sur place",
        support: "Support technique - mail",
        orderMethod: "Gestion des commandes - mail",
        
      },
    },
    silver: {
      planName: "argent",
      planDescription:
        "Recommandé pour les restaurants",
      priceTag: "CHF 299.-",
      functionalities: {
        templates: "1 Application - 2 interfaces au choix",
        paymentMethod: "Possibilité de paiement en ligne",
        support: "Support technique - appels & mail",
        orderMethod: "Gestion des commandes - plateforme ",
        web: "Site internet - Application web",
        fidelity: "Code promos et comptes avec points",
      },
    },
    gold: {
      planName: "or",
      priceTag: "CHF 999.-",
      planDescription:
        "Recommandé pour les chaînes de restaurants ",
      functionalities: {
        templates: "1 Application - Tous vos restaurants",
        paymentMethod: "Paiement en ligne",
        support: "Support technique  - 7/7 & 24/24",
        orderMethod: "Gestion de commandes - plateforme",
        
        web: "Site internet - Application web",
        fidelity: "Code promos",
        stats: "Statistiques de travail"
      },
    },
  },
  title: "Choisissez l'abonnement qui vous convient",
};

// First Layer
export const ContactFormData: BoxContent = {
  id: "formdata",
  slideId: "formdata",
  firstTitle: "Rentrez en contact avec nous",
  secondTitle: "",
  paragraph: "Nous répondrons aussi rapidement que possible",
  src: "",
};

/* --- Screen sizes --- */
const screenSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${screenSizes.mobileS})`,
  mobileM: `(max-width: ${screenSizes.mobileM})`,
  mobileL: `(max-width: ${screenSizes.mobileL})`,
  tablet: `(max-width: ${screenSizes.tablet})`,
  laptop: `(max-width: ${screenSizes.laptop})`,
  laptopL: `(max-width: ${screenSizes.laptopL})`,
  desktop: `(max-width: ${screenSizes.desktop})`,
  desktopL: `(max-width: ${screenSizes.desktop})`,
};

// Main button title
export const otherData = {
  btnTitle: "Découvrez en plus",
  header: {
    brandName: "Elysium",
    subtitle: "Web & Apps pour la restauration",
    buttons: {
      contact: "Contact",
      mainWeb: "Autres services"
    }
  },
  plan: {
    paymentInfo: {
      month: "Mois + TVA",
    },
  },
  footer: {
    columnNames: {
      contact: "Contact",
      security: "Securité",
      social: "Résaux Sociaux",
    },
    bug: "Vous avez trouvé un bug ?",
  },
};

import React, { useState } from "react";

export interface ToastInfo {
  message: string;
  shouldDisplayToast: boolean;
}

type ToastType = [ToastInfo, React.Dispatch<React.SetStateAction<ToastInfo>>];

// These values will be used only if no Provider is found, which should not be the case
export const ToastContext = React.createContext<ToastType>([
  { message: "Contact Webmaster if you see this text", shouldDisplayToast: true },
  () => null,
]);

export const ToastProvider = (props: any) => {
  const [toastInfo, setToastInfo]: ToastType = useState<ToastInfo>({ message: "", shouldDisplayToast: false });
  return (
    <ToastContext.Provider value={[toastInfo, setToastInfo]}>
      {props.children}
    </ToastContext.Provider>
  );
};

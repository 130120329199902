import HeaderButton from "./HeaderButton";
import styled from "styled-components";
import { device, otherData } from "../data/data-french";
import { FlexContainer, HeaderButtonStyles } from "../styles/variables";

interface Props {
  id?: string;
}

const HeaderContainer = styled.div`
  /* Allows to click through but children won't be 'clickable' */
  pointer-events: none;

  /* Not a flex child */
  position: fixed;
  top: 0;
  z-index: 1;
  /* Don't specify height in order to avoid problem with backround being to small onHover */
  /* height: var(--height-header); */
  width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "space-between", align: "stretch" })}

  /* Other Styles */
  border: var(--show-borders) solid gray;

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* Flex child */
    flex: 0 0 15vh;
    width: 100%;

    /* --- Flex parent --- */
    ${FlexContainer({
      flow: "row",
      justify: "center",
      align: "stretch",
    })}

    /* Other Styles */
    border: 0px solid gray;
  }
`;

const LogoContainer = styled.div`
  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}

  transition: background-color 0.7s, color 1s;

  &:hover {
    color: var(--white);
    background-color: var(--lighter-black);
  }
  ${HeaderButtonStyles}
  /* Other Styles */
  border: none;
  margin: 1rem;
  padding: 1rem;

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* Override for now */
    display: none;
  }
`;

const BrandLogo = styled.div`
  /* Restore 'clickability' */
  pointer-events: auto;
  text-transform: uppercase;

  /* Flex child */
  flex: 0 0 auto;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "space-around", align: "center" })}

  /* Other Styles */
  
  /* border: var(--show-borders) solid gray; */
  font-weight: var(--font-weight-logo-and-bold-title);
  font-size: var(--font-size-brandname);
  margin: 0rem;
  padding: 0 3.5rem;

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* Flex child */
    flex: 0 0 100%;

    /* --- Flex parent --- */
    ${FlexContainer({ flow: "row", justify: "space-around", align: "center" })}

    /* Other Styles */
    border: var(--show-borders) solid gray;
    padding: 0 3.5rem;
  }
`;

const SubTitle = styled.h4`
  /* Special Styles */
  text-decoration: overline;
  text-transform: uppercase;

  /* Other Styles */
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
`;

const HeaderButtonsContainer = styled.div`
  /* Flex child */
  /* flex: 0 0 100%; */
  flex: 1 0 auto;
  min-width: 30%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "flex-end", align: "center" })}

  /* Other Styles */
  border: var(--show-borders) solid rgb(36, 27, 27);

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    display: none;
  }
`;

const Header = (props: Props) => {
  return (
    <HeaderContainer className="Header">
      <LogoContainer>
        <BrandLogo>{otherData.header.brandName}</BrandLogo>
        <SubTitle>{otherData.header.subtitle}</SubTitle>
      </LogoContainer>

      <HeaderButtonsContainer className="HeaderButtonContainer">
        <HeaderButton title={otherData.header.buttons.contact}  />
        <HeaderButton title={otherData.header.buttons.mainWeb} url="https://www.elysium.cc" />
      </HeaderButtonsContainer>
    </HeaderContainer>
  );
};

export default Header;

import React from "react";
import styled from "styled-components";
import { device } from "../data/data-french";
import { FlexContainer } from "../styles/variables";

interface Props {
  src: string;
  className?: string;
}

interface StyledProps {
  className?: string;
  src: string;
}

const PhotoContainerDiv = styled.div`
  /* Flex child */
  flex: 0 0 10%; // Why
  width: 100%;
  height: 70%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "center" })}
  display: ${(props: StyledProps) =>
    props.src.length < 1 || typeof props.src == "undefined"
      ? "none;"
      : "flex;"};

  border: var(--show-borders) solid rgb(100, 67, 13);

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* Flex child */
    flex: 1 0 auto;
    width: 100%;

    /* --- Flex parent --- */
    ${FlexContainer({flow: 'column', justify: 'flex-end', align: 'center'})}
    
  }
`;

// Only one specific image needs to be resized
const Image = styled.img`
  /* Conditional Styles come first */
  max-width: ${(props: Props) =>
    props.src === "static_assets/3d-flame-blockchain.png" ? "40%;" : "50%;"};
  max-height: 100%;

  /* Other Styles */
  border: var(--show-borders) solid blue;

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    max-height: 80%;
  }
`;

const Photo: React.FC<Props> = ({ className, ...props }) => {
  return (
    <PhotoContainerDiv src={props.src} className={className}>
      <Image src={props.src} />
    </PhotoContainerDiv>
  );
};

export default Photo;

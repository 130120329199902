import styled from "styled-components";
import { device } from "../data/data-french";
import { Paragraph, Label, FlexContainer } from "../styles/variables";
import { DiscoverButton } from "./DiscoverButton";
interface Props {
  firstTitle?: string;
  secondTitle?: string;
  paragraph?: string;
  slideId: string;
  btnTitle?: string;
}

const TextboxContainer = styled.div`
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "stretch" })}

  /* Flex child */
  /*  'flex-basis' doesn't work for some reason but 'width' does */
  min-width: 50%;
  max-width: 70%;

  border: var(--show-borders) solid var(--imperial-red);

  @media ${device.tablet},
    ${device.mobileL},
    ${device.mobileL},
    ${device.mobileM} {
    width: 100%;
  }
`;

const TextboxLabel = styled(Label)``;

const TextboxParagraph = styled(Paragraph)``;

const Textbox = (props: Props) => {
  return (
    <TextboxContainer>
      <TextboxLabel>{props.firstTitle}</TextboxLabel>
      <TextboxLabel>{props.secondTitle}</TextboxLabel>
      <TextboxParagraph>{props.paragraph}</TextboxParagraph>
      <DiscoverButton id={props.slideId} btnTitle={props.btnTitle}>
        {typeof props.btnTitle === "undefined"
          ? "Discover more"
          : props.btnTitle}
      </DiscoverButton>
    </TextboxContainer>
  );
};

export default Textbox;

import React from "react";
import styled from "styled-components";
import { AdvantagesData, device, Reasons } from "../data/data-french";
import { FlexContainer } from "../styles/variables";

interface Props {
  index: number;
  property: keyof Reasons;
  n?: number;
}

const CardContainer = styled.div`
  // Flex child
  flex: 1 1 0px;
  height: auto;
  max-height: 70%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "stretch" })}

  /* Other Styles */
  border: var(--show-borders) solid var(--lighter-black);
  border-radius: 2rem;
  box-shadow: 0 12px 28px rgba(140, 149, 159, 0.3);
  color: var(--lighter-black);
  margin: 0 0.5rem;
  padding: 1.3rem 1.3rem;

  overflow-y: hidden;

  transition: background-color 0.7s, max-height 0.7s, flex-basis 2s, color 1s;

  &:hover {
    background-color: var(--lighter-black);
    max-height: 100%;
    flex-basis: 30%;
    color: var(--white);

    #paragraph {
      display: block;
      transition: display 2s;
    }
  }

  &:hover #paragraph {
    display: block;
  }

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    /* --- Flex child --- */
    flex: 1 0 25%;
    /* height: 20%; */
    width: 100%;

    /* --- Flex parent --- */
    ${FlexContainer({ flow: "row", justify: "flex-start", align: "stretch" })}

    /* Other Styles */
    margin: 1rem 0;

    /* Special Styles */
    transition: background-color 0.7s, max-height 0.7s, flex 2s, color 1s,
      max-width 2s;

    &:hover {
      max-height: 100%;
      /* height: 30%; */
      flex: 1 0 35%;

      #paragraph {
        display: block;
      }
    }

    transition: max-width 2s;

    &:hover img {
      max-width: 25vh;
    }
  }
`;

const CardImageContainer = styled.div`
  // Flex child
  flex: 0 0 50%;

  /* --- Flex parent --- */
  ${FlexContainer({
    flow: "column",
    justify: "center",
    align: "flex-start",
  })}

  /* Other Styles */
    border: var(--show-borders) solid red;
  padding: 1rem 0 1rem 1rem;
  /* @media ${device.laptop} {
    flex: 0 0 10rem;
  } */

  /* @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    flex: 1 1 0px;
    justify-content: center;
  } */
`;

const CardPhotoWrapper = styled.div`
  // Flex child
  flex: 0 0 100%;
  width: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "flex-start" })}

  /* Other Styles */
  border: var(--show-borders) dashed green;
`;

const CardPhoto = styled.img`
  /* Flex child */
  max-width: 50vh;
  width: 100%;

  /* Other Styles */
  border: var(--show-borders) solid black;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    max-width: 17vh;
  }
`;

const CardText = styled.div`
  // Flex child
  flex: 0 0 50%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "stretch" })}

  /* Other Styles */
   border: var(--show-borders) solid grey;
  padding: 1rem;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    /* Flex child */
    flex: 1 1 0px;
  }
`;

const Reason = styled.div`
  // Flex child
  flex: 0 0 auto;

  /* Other Styles */
  font-size: 1.2rem;
`;

const CardParagraph = styled.div`
  display: none;

  /* Other Styles */
  border: var(--show-borders) solid black;
  text-align: justify;
`;

export const Card: React.FC<Props> = (props: Props) => {
  return (
    <CardContainer key={props.index}>
      <CardImageContainer>
        <CardPhotoWrapper>
          <CardPhoto src={AdvantagesData[props.property].src} />
        </CardPhotoWrapper>
      </CardImageContainer>
      <CardText>
        <Reason>{AdvantagesData[props.property].reason}</Reason>
        <CardParagraph id="paragraph">
          {AdvantagesData[props.property].paragraph}
        </CardParagraph>
      </CardText>
    </CardContainer>
  );
};

import React from "react";
import styled from "styled-components";
import { device } from "../data/data-french";
import { DiscoverButton, scrollToSlide } from "./DiscoverButton";

interface Props {
  id: string;
  btnTitle: string;
}

interface StyledProps {
  id: string;
}

const ReturnButtonStyled = styled(DiscoverButton)<StyledProps>`
  width: 7%;

  /* Other Styles */
  background-color: transparent;
  /* border: 1px solid var(--lighter-black); */
  color: var(--lighter-black);
  margin: 0 calc(1rem + 1%);

  &:hover {
    color: white;
    background-color: var(--lighter-black);
  }

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    width: 20%;
  }
`;

export const ReturnButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <ReturnButtonStyled {...props}>
      {children}
      {props.btnTitle}
    </ReturnButtonStyled>
  );
};

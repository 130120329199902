// import React from './../React'
import Header from "./Header";
import Textbox from "./Textbox";
import Photo from "./Photo";
import styled, { css } from "styled-components";
import React from "react";
import { BoxContent, device } from "../data/data-french";
import { start } from "repl";
import { startColor, endColor } from "../styles/variables";
import { FlexContainer } from "../styles/variables";

interface Props {
  invertedDivs?: boolean;
  oddBox?: boolean;
  content: BoxContent;
  id: string;
  slideId: string;
  btnTitle?: string;
}

interface StyledProps {
  invertedDivs?: boolean;
  oddBox?: boolean;
}

export const Boxed = styled.div<StyledProps>`
  /* Conditional Styles come first */
  background: linear-gradient(to bottom, ${startColor}, ${endColor});
  ${(props) =>
    props.oddBox &&
    `background: linear-gradient(to bottom, ${endColor},  ${startColor});`};

  /* Flex child */
  flex: 0 0 auto;
  height: 100%;
  width: var(--box-width);

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}

  /* Other Styles */
  border: var(--show-borders) solid black;

  /* Special Styles */
  overflow-y: scroll;

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    /* --- Flex child --- */
    height: auto;
    width: 100%;

    /* Other Styles */
    border: var(--show-borders) solid black;
  }
`;
export const BoxedContainer = styled.div<StyledProps>`
  /* Flex child */
  flex: 0 0 auto;
  /* Fit all the available horizontal space */
  width: 100%;
  /* Fill to parent height */
  height: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "flex-start", align: "center" })}

  /* Other Styles */
  border: var(--show-borders) solid rgb(144, 153, 238);

  @media ${device.mobileM}, ${device.mobileL}, ${device.tablet} {
    flex-flow: column nowrap;

    /* Other  Styles */
    border: var(--show-borders) solid black;
  }
`;

const InvertedDiv = styled.div`
  /* Flex child */
  flex: 1 1 0px;
  height: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "column", justify: "center", align: "stretch" })}

  /* Other Styles */
  border: var(--show-borders) solid rgb(165, 25, 25);

  /* Special Styles */
  order: ${(props: StyledProps) => props.invertedDivs && 2};

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* Flex child */
    flex: 0 0 50%;
    max-height: 50%;
    width: 100%;

    /* --- Flex parent --- */
    ${FlexContainer({ flow: "column", justify: "flex-end", align: "center" })}

    /* Special Styles */
    /* No need to invert text and images on mobile view */
    order: 0;
  }
`;

const MainText = styled.div`
  /* Flex child */
  flex: 1 1 0px;
  height: 100%;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "center", align: "flex-end" })}

  /* Other Styles */
  border: var(--show-borders) solid purple;
  padding: 0 0 8rem 0;

  @media ${device.tablet}, ${device.mobileL}, ${device.mobileM} {
    /* Flex child */
    width: 100%;

    /* --- Flex parent --- */
    ${FlexContainer({ flow: "column", justify: "flex-start", align: "center" })}
    padding: 4rem 0 0 0;
  }
`;

const Box: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Boxed oddBox={props.oddBox} id={props.id}>
      <BoxedContainer>
        <InvertedDiv invertedDivs={props.invertedDivs}>
          {/* <ThreeDModel/> */}
          <Photo src={props.content.src} />
          {children}
          {/* <img src="casual-life-3d-phone.png" alt="Illustration by Icons 8 from Ouch!" style={{width: '100%', height: "auto"}}/> */}
        </InvertedDiv>
        <MainText className="MainText">
          <Textbox
            slideId={props.slideId}
            firstTitle={props.content.firstTitle}
            secondTitle={props.content.secondTitle}
            paragraph={props.content.paragraph}
            btnTitle={props.btnTitle}
          />
        </MainText>
      </BoxedContainer>
    </Boxed>
  );
};

export default Box;

import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import { BigButton } from "../styles/variables";

interface Props {
  id: string;
  btnTitle?: string;
  className?: string;
}

const DiscoverBigButton = styled(BigButton)``;

export function scrollToSlide(id: string) {
  try {
    document.querySelector("#" + id)!.scrollIntoView({ behavior: "smooth" });
  } catch (e) {
    window.open('mailto:restaurants@elysium.cc');
  }
}

export const DiscoverButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <DiscoverBigButton
      className={props.className}
      onClick={() => scrollToSlide(props.id)}
    >
      {props.btnTitle == "" ? children : props.btnTitle}
    </DiscoverBigButton>
  );
};

import React, { useContext } from "react";
import styled from "styled-components";
import { ToastContext } from "../Context/ToastContext";
import { device } from "../data/data-french";
import { FlexContainer } from "../styles/variables";

interface Props {
  message: string;
  id?: string;
  className?: string;
}

interface StyledProps {
  message: string;
  shouldDisplayToast: boolean;
}

const ToastContainer = styled.div<StyledProps>`
  /* I believe setting position: fixed negates flex: child */
  /* Unfortunately, we had to kick him out of the family */
  /* Kicked-out-of-the-family Styles */
  width: 50%;
  height: 10vh;

  /* --- Flex parent --- */
  ${FlexContainer({ flow: "row", justify: "center", align: "center" })}

  /* Other Styles */
  background-color: var(--success);
  border-radius: var(--border-radius-header-button);
  padding: 1rem;
  color: var(--white);

  /* Special styles */
  position: fixed;
  top: 5vh;
  margin: 0 auto;
  z-index: 3;

  /* Override previous styles */
  display: none;
  ${(props: StyledProps) => props.shouldDisplayToast && `display: flex;`}

  @media ${device.mobileL} {
    width: 70%;
    height: 7vh;
  }
`;

export const Toast: React.FC<Props> = ({ children, className, ...props }) => {
  const { message } = props;
  const [toastInfo, setToastInfo] = useContext(ToastContext);

  return (
    <ToastContainer
      className={className}
      message={toastInfo.message}
      shouldDisplayToast={toastInfo.shouldDisplayToast}
    >
      {toastInfo.message}
    </ToastContainer>
  );
};
